<template>
  <div>
    <el-dialog v-model="detailVisible" title="明细" width="65%">
      <div>
        <el-row>
          <el-col :span="4">
            <el-button type="primary" size="small" @click="onBatchDistribution">
              批量发放物品
            </el-button>
          </el-col>

          <el-col :span="17">
            <el-checkbox-group v-model="selectDynamicLabel">
              <el-checkbox
                v-for="(item, index) in dynamicData"
                :label="item.label"
                :key="index"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
          <el-col :span="2" :push="1">
            <el-button type="primary" size="small" @click="bindPrint">
              打印
            </el-button>
          </el-col>
        </el-row>

        <div id="printTable">
          <el-table
            :data="tableData"
            border
            style="width: 100%; margin-top: 20px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              v-for="(item, index) in tableColumns"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <!-- 明细 -->
              <template v-slot="{ row }" v-if="item.prop === 'state'">
                {{ row.state ? '已发放' : '未发放' }}
              </template>

              <template #default="{ row }" v-else-if="item.prop === 'action'">
                <el-button
                  link
                  :disabled="row.state === 1"
                  type="primary"
                  size="small"
                  @click="onIssue(row.id)"
                >
                  发放物品
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <el-row style="margin-top: 20px">
          <el-col>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100, 500]"
              :page-size="search.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="search.total"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="btnCancel">取消</el-button>
          <el-button type="primary" @click="btnConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { dynamicData, selectDynamicLabel, tableColumns } from '../dynamic'

import {
  orderDetailsAPI,
  issueItemsAPI,
  batchDistributionAPI
} from '@/api/finance'
import { ElMessage } from 'element-plus'
import print from 'print-js'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },

  orderid: {
    type: String,
    required: true
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const detailVisible = useVModel(props) // 得到一个相应式的数据

/** 表格数据 S */
const search = ref({
  page: 1,
  limit: 5,
  total: 0,
  id: ''
})
watch(
  () => props.orderid,
  (val) => {
    search.value.id = val
    onOrderDetails()
  }
)
const tableData = ref([])
const onOrderDetails = async () => {
  const { data, total } = await orderDetailsAPI(search.value)
  search.value.total = total
  tableData.value = data
  console.log(data)
}

// 发放
const onIssue = async (id) => {
  await issueItemsAPI({ id })
  onOrderDetails()
  ElMessage.success('物品发放成功')
}

// 批量发放
const ids = ref([])
// 多选框选中数据
const handleSelectionChange = (selection) => {
  // 获取所有选中项的gh(工号)属性的值
  ids.value = selection.map((item) => item.id).join()
}
const onBatchDistribution = async () => {
  await batchDistributionAPI({ ids: ids.value })
  onOrderDetails()
  ElMessage.success('物品发放成功')
}

// 打印
const bindPrint = () => {
  print({
    printable: 'printTable',
    type: 'html',
    header: null,
    targetStyles: ['*'],
    style: '@page {margin:0 10mm}'
  })
}

/** 表格数据 E */

/** 明细弹窗 S */
const btnCancel = () => {
  detailVisible.value = false
}
const btnConfirm = () => {
  detailVisible.value = false
}

/** 明细弹窗 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onOrderDetails()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onOrderDetails()
}

/**
 *  分页 E
 */
</script>

<style></style>
