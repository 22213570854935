<template>
  <div>
    <el-dialog v-model="detailVisible" title="备注" width="45%">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        status-icon
      >
        <el-form-item label="备注" prop="reason">
          <el-input
            type="textarea"
            rows="4"
            style="width: 600px"
            v-model="ruleForm.reason"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="btnCancel">取消</el-button>
          <el-button type="primary" @click="btnConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'

import { putAsARefundFeeAPI } from '@/api/finance'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  orderid: String,
  onPayOrderList: {
    type: Function
  }
})

const detailVisible = useVModel(props)

const ruleForm = ref({
  reason: ''
})

defineEmits(['update:modelValue'])

const btnCancel = () => {
  // 在取消按钮点击时重置表单数据
  ruleForm.value.reason = ''
  detailVisible.value = false
}

const ruleFormRef = ref(null)

const rules = ref({
  reason: [
    {
      required: true,
      message: '请输入备注',
      trigger: 'blur'
    }
  ]
})
const btnConfirm = async () => {
  const demoRule = {
    required: true,
    message: '请输入备注',
    trigger: 'blur'
  }
  rules.value.reason = [demoRule]
  if (!ruleFormRef.value || !(await ruleFormRef.value.validate())) {
    ElMessage.error('请检查表单')
    return
  }

  await putAsARefundFeeAPI({
    id: props.orderid,
    reason: ruleForm.value.reason
  })

  ElMessage.success('已提交退费')
  // 在确认按钮点击后重置表单数据
  ruleForm.value.reason = ''
  detailVisible.value = false
  props.onPayOrderList()
}
</script>

<style></style>
