import request from '@/utils/request'

// 缴费列表
export const paymentListAPI = (params) => {
  return request({
    url: '/paysetList',
    method: 'GET',
    params
  })
}

// 添加缴费设置
export const addPaymentSettingsAPI = (data) => {
  return request({
    url: '/addPayset',
    method: 'POST',
    data
  })
}

// 缴费项目选择列表
export const paymentItemAPI = (data) => {
  return request({
    url: '/itemallList',
    method: 'POST',
    data
  })
}

// 删除
export const payTheFeesDeleteAPI = (params) => {
  return request({
    url: '/paysetDel',
    method: 'GET',
    params
  })
}

// 修改时间
export const changeTheTimeAPI = (data) => {
  return request({
    url: '/paysetSave',
    method: 'POST',
    data
  })
}

/** 明细 S */
// 数据
export const paysetDetailAPI = (data) => {
  return request({
    url: '/paysetDetail',
    method: 'POST',
    data
  })
}
// 删除
export const paysetDelAPI = (params) => {
  return request({
    url: '/paysetDel',
    method: 'GET',
    params
  })
}
// 新增
export const addDetailpaysetAPI = (data) => {
  return request({
    url: '/addDetailpayset',
    method: 'POST',
    data
  })
}

// 修改
export const paydetailsetSaveAPI = (data) => {
  return request({
    url: '/paydetailsetSave',
    method: 'POST',
    data
  })
}

/** 明细 E */
