export default () => [
  {
    label: '项目名称',
    prop: 'payment_name'
  },
  {
    label: '执收编码',
    prop: 'zsbm'
  },
  {
    label: '数量',
    prop: 'sl'
  },
  {
    label: '收费金额',
    prop: 'je'
  },
  {
    label: '发放状态',
    prop: 'state'
  },
  {
    label: '操作',
    prop: 'action'
  }
]
